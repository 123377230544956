// --- Обнуление стилей ---
@import "_reset";

// --- Переменные ---
@import "_vars";

// --- Импорт шрифтов ---
@import "_fonts";

:root {
	--app-height: 100%;

	--white: #fff;
	--black: #000;
	--gray: #EEEEEE;
	--yellow: #FFF500;
}
* {
	user-select: none;
}

html {
	height: 100%;
}

body {
	font-family: "Lacquer";
	font-weight: 400;
	color: var(--black);
	min-height: var(--app-height);
	height: 100%;
	min-width: 100%;
	max-width: 100vw;
	overflow-x: hidden;
	background-color: white;
	touch-action: none !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&._lock {
		overflow: hidden;
	}
}
@media (prefers-color-scheme: light) {
    body {
        background-color: white;
        color: var(--black);
    }
}
@media (prefers-color-scheme: dark) {
    body {
        background-color: white;
        color: var(--black);
    }
}

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: var(--app-height);
	min-width: 320px;
	height: 100%;
	position: relative;
	overflow: hidden;
	margin: 0 auto;
	background-color: white;
}

.container {
	max-width: $container + px;
	width: 100%;
	margin: 0 auto;
	padding: 0 16px;
}

@import "UI/navigation";
@import "UI/modal";
@import "UI/notification";
@import "../../components/Pages/Splash/SplashScreen";
@import "../../components/Pages/Home/HomeScreen";
@import "../../components/Pages/Home/Projects/ProjectsScreen";
@import "../../components/Pages/Home/Projects/Project/Project";
@import "../../components/Pages/Home/Ratings/RatingsScreen";
@import "_breakpoints";



.preloader_image_in{
	width: 100%;
	height: 100%;
	background-image: url(../img/splash/potato_crown.png);
	background-size: 80%;
	background-position: center 0;
	background-repeat: no-repeat;
}
.gameWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	justify-content: space-around;
	padding: 0 0 75px 0;
	.clicker__sessionText {
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 18px;
		text-align: center;
	}
	.clicker__clickArea {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 315px;
    width: 100%;
    z-index: 75;
	}

	.clicker__clickValue {
		display: block;
		align-items: center;
		flex-direction: row;
	}
	.clicker__clickerHero {
		display: flex;
		align-self: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		z-index: 5;
		position: relative;
		picture {
			display: flex;
			align-self: center;
			height: 75%;
			width: auto;
			position: relative;
		}
		img {
			align-self: center;
			display: flex;
			height: 100%;
			width: auto;
			position: relative;
			z-index: 10;
			border-radius: 50%;
		}
	}
	.clicker__progressBox {
		display: flex;	
		position: absolute;
		height: 103.5%;
		width: 103.5%;
	}
	.clicker__session {
		display: flex;
		align-items: center;
		text-align: center;
		font-family: "Kumbh";
		border-radius: 44px;
		padding: 5px 10px;
		margin-bottom: -25px;
		background-color: var(--yellow);
		overflow: hidden;
		min-width: fit-content;
		min-height: 36px;
		z-index: 100
	}
	.clicker__sessionProgress {
		display: flex;
		align-items: center;
		margin-left: 5px;
		font-weight: 400;
		font-size: 18px;
		text-transform: lowercase;
	}
	@media (max-height: 686px) {
		.clicker__clickArea {
			height: 237px;
			width: 100%;
		}
	}
}
