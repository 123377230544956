.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    max-height: var(--app-height);
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    overflow-y: auto;
    z-index: 1000;

    &__close {
        position: absolute;
        top: 20px;
        right:24px;
        z-index: 100;
        svg {
            width: 20px;
            height: 22px;
            path {
                fill: #ffffff;
            }
        }
    }

    &._active {
        opacity: 1;
        visibility: visible;
        pointer-events: all;

        .modal__content {
            transform: translateY(0);
            transition: transform 0.5s ease, opacity 0.5s ease;
            &.black_bg {
                background-color: var(--black);
            }
            &.white_bg {
                background-color: var(--white);
            }
        }
    }

    &__wrapper {
        height: auto;
        padding: 15px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    &__content {
        max-width: 480px;
        width: 100%;
        border-radius: 32px 32px 0 0;
        background: #fff;
        position: relative;
        transform: translate(0, 100%);
        transition: all .5s ease;

        .main__btn {
            width: 100%;
        }
    }
}

.modal {

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 250px;
    }

    &__icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__title {
        font-size: 22px;
        text-align: center;
        margin-bottom: 22px;
    }

    &__text {
        text-align: center;
        margin-bottom: 22px;
        max-width: 280px;
        line-height: 1.5;
    }

    &__value {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 28px;
        border-radius: 50px;
        padding: 10px 15px;
        font-size: 32px;
        background: var(--yellow);

        &_white {
            background: var(--white);
            border: 2px solid #000;
        }

        svg {
            width: 22px;
            height: 22px;
            margin-left: 10px;
        }
    }

    &__input {
        margin-bottom: 26px;
        max-width: 301px;
        width: 100%;
        display: flex;

        input {
            width: 100%;
            padding: 10px 16px;
            border: 2px solid #000;
            border-radius: 12px;

            &::placeholder {
                opacity: 0.4;
            }
        }
    }

    &__btn {
        font-size: 28px;
        max-width: 301px;
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 23px;
        }

        &.btn-inactive {
            font-size: 18px;
        }

        &.btn {
            box-shadow: 5px 5px 0 #fff, 5px 5px 0 1.5px #000;

            @media (any-hover: hover) {
                &:hover {
                    transform: translate(5px, 5px);
                    box-shadow: 0 0 0 #fff, 0 0 0 0 #000;
                }
            }

            &.hover {
                transform: translate(5px, 5px);
                box-shadow: 0 0 0 #fff, 0 0 0 0 #000;
            }
        }
    }

    &__close_btn {
        position: absolute;
        top: 30px;
        right: 30px;
    }
}

.wallets {
    align-self: stretch;
    margin: 0 -40px;
    padding-bottom: 10px;
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &__list {
        display: flex;
        width: fit-content;
        padding: 0 40px;

    }

    &__item {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (any-hover: hover) {
            &:hover {
                .wallets__image {
                    box-shadow: 0 0 0 0 #fff, 0 0 0 0 #000;
                    transform: translate(5px, 5px);
                }
            }
        }

        &.hover {
            .wallets__image {
                box-shadow: 0 0 0 0 #fff, 0 0 0 0 #000;
                transform: translate(5px, 5px);
            }
        }
    }

    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--black);
        width: 60px;
        min-width: 60px;
        height: 60px;
        margin-bottom: 10px;
        box-shadow: 5px 5px 0 -2px #fff, 5px 5px 0 0 #000;
        transition: all .1s ease;

        img {
            width: 32px;
            height: 32px;
        }
    }

    &__name {
        font-size: 14px;
        color: var(--black);
    }
}