.error_404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.img_404 {
  width: 70%;
  margin-top: 20px;
}

.img_404_face {
  margin-top: -9%;
  width: 8%;
}

.p_not_found {
  font-size: 30px;
  margin-top: 3%;
  text-align: center;
}

.d_404_desc {
  margin-top: 5%;
  padding: 0 15% 0 15%;
  text-align: center;
  font-weight: 100;
  margin-bottom: 15%;
}

@media screen and (min-width: 1200px) {
  .d_404_desc {
    margin-top: 5%;
    padding: 0 15% 0 15%;
    text-align: center;
    font-weight: 100;
    margin-bottom: 5%;
  }
}

.profile_user_name{
  font-size: 38px;
}
.projects .container_btn_profile {
  position: relative;
  top: -30px;
  margin-top: -40px;
}
.frens .container_btn_profile {
  position: relative;
  top: -30px;
  margin-top: -40px;
}
.main .container_btn_profile {
  margin-top: -10px;
}
.projects .container_btn_profile,
.frens .container_btn_profile,
.ratings .container_btn_profile {
  position: relative;
  top: -30px;
  margin-top: -50px;
}
.top_logo img{
  width: 42px;
  height: auto;
}


// for rating tables
.highlight {
  background: #000;
  .ratings__listId {
      span {
          color: #ffffff;
      }
  }
  .ratings__listName {
      span {
          color: #ffffff;
      }
  }
}

// main btn styles

.mainBtn {
  font-size: 28px;
  width: 100%;
  max-width: 301px;
  margin-bottom: 22px;
  position: relative;
  z-index: 100;
  @media (max-height: 685px) { 
    font-size: 22px;
    height: 42px;
  }
  span {
      background: var(--yellow);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      padding: 6px 8px;
      position: absolute;
      top: 50%;
      right: 1px;
      transform: translate(0, -50%);
      height: 48px;
      padding: 0 19px;
      min-width: 75px;
      svg {
        height: 12px;
        width: 12px;
      }
      @media (max-height: 685px) {
        height: 38px;
      }
  }
}

.gameWrapper {
  .mainBtn {
		margin-top: 0px;
		margin-bottom: -15px;
	}
  @media (max-height: 685px) {
		.mainBtn {
			margin-bottom: 10px;
		}
	}
	@media (max-height: 730px) {
		.mainBtn {
			margin-bottom: 0px;
		}
	}
}