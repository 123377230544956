.activity {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: calc(100% - 75px);
    &__cats {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
    &__cat {
        display: flex;
        align-items: center;
    }
    &__cat-item {
        display: flex;
        flex-direction: column;
        padding: 13px 0;
        margin-top: 6px;
        gap: 6px;
        span {
            font-weight: 400;
            font-size: 22px;
            text-transform: lowercase;
            color: #000;
            max-width: 75%;
        }
        button {
            height: 38px;
            background: #fff500;
            padding: 0 20px;
            border-radius: 44px;
            font-weight: 400;
            font-size: 22px;
            text-transform: lowercase;
            color: #000;
        }
    }
    &__cat-item:first-child {
        flex-grow: 1;
    }
    &__thumbnails {
        display: flex;
        align-items: center;
        padding: 0 0 0 5px;
        gap: 5px;
    }
    &__thumbnail-img {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        img {
            width: 100%;
        }
    }
    &__thumbnail-extra {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        background-color: #000;
        color: #fff;
        font-size: 14px;
    }
    &__event-banner {
        width: 100%;
        margin: 0 auto;
        img {
            width: 100%; 
            height: auto; 
            max-width: 100%; 
        }
    }
}