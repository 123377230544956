.main {
    height: 100%;
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: calc(100% - 75px);
    }

    &__text {
        font-size: 24px;
        text-align: center;
        font-family: $secondaryFont;
    }

    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 300px;
        width: 100%;
        aspect-ratio: 1 / 1;
        position: relative;
        border-radius: 50%;
        margin-bottom: 20px;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 70%;
            height: 70%;
            object-fit: contain;
        }

        &_invert {
            background: var(--gray);

            img {
                transform: translate(-50%, -50%) scale(-1, 1);
            }
        }

    }

    &__image_yellow {
        background: var(--yellow);
    }

    &__image_gray {
        background: var(--gray);
    }

    &__widget_section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        
        padding-bottom: 16px;
    }
}


.tasks-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__title {
        font-size: 32px;
        text-align: center;
        margin-bottom: 24px;
        max-width: 300px;
    }

    &__list {
        width: 100%;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__link {
        font-size: 17px;
        color: var(--black);
        transition: all .5s ease;

        &:hover {
            padding-left: 8px;
        }
    }

    &__value {
        display: flex;
        align-items: center;
        border-radius: 50px;
        background: var(--yellow);
        padding: 6px 16px;

        svg {
            width: 12px;
            min-width: 12px;
            height: 12px;
            margin-left: 2px;
        }
    }
}

.tasks {
    width: 100%;
    padding:60px 21px 48px 21px;
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        font-size: 32px;
        text-align: center;
        position: relative;
        margin-bottom: 24px;

        span {
            position: absolute;
            left: 105%;
            top: 50%;
            transform: translate(0, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 8px;
            background: var(--yellow);
            border-radius: 50px;
        }

        svg {
            width: 12px;
            height: 12px;
        }
    }

    &__list {
        width: 100%;
    }

    &__item {

        

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
    &__item.hidden {
        display: none;
    }
    &__item.waiting {
        display: none;
    }

    &__link {
        display: flex;
        align-items: center;
        transition: all .5s ease;


        &_done {
            .tasks__name {
                text-decoration: line-through;
            }

            .tasks__value {
                background: var(--gray);

                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
        .tasks__value_done {
            color: var(--yellow);
            border: 1.5px solid var(--yellow);
            background-color: black;
            font-size: 18px;
        }
        .tasks__value_check {
            color: black;
            background-color: var(--gray);
            font-size: 18px;
        }
    }

    &__image, &__icon {
        width: 45px;
        min-width: 45px;
        height: 45px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 12px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

        }
    }
    &__icon {
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 50%;
            height: 50%;
            path {
                fill: white;
            }
        }
    }


    &__name {
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        flex: 1 1 auto;
        margin-right: 12px;
        color: var(--black);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__value {
        font-size: 22px;
        padding: 6px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        border-radius: 50px;
        background: var(--yellow);
        color: var(--black);
        min-width: 80px;
        min-height: 40px;

        svg {
            width: 12px;
            height: 12px;
        }
    }
}
.black_bg {
    .tasks__name {
        color: white;
    }
}

/* Task Poup Animations */

.task-list-enter {
    transform: translateX(0);
}
.task-list-enter-active {
    transform: translateX(-100%);
    transition: transform 300ms;
}
.task-list-exit {
    transform: translateX(-100%);
}
.task-list-exit-active {
    transform: translateX(0);
    transition: transform 300ms;
}

.popup-enter {
    transform: translateX(100%);
}
.popup-enter-active {
    transform: translateX(0);
    transition: transform 300ms;
}
.popup-exit {
    transform: translateX(0);
}
.popup-exit-active {
    transform: translateX(100%);
    transition: transform 300ms;
}


#slider {
    position: absolute;
    width: 100px;
    height: 100px;
    background: blue;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
}

.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-100%); }
}

.popup-column-wrapper {
    display: flex;

}
#ton-connect-button,
#ton-connect-button div {
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#ton-connect-button button[data-tc-button="true"] {
  
    width: 100%;
    max-width: 301px;
    position: relative;
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 14px 34px;
    border-radius: 60px;
    color: var(--white);
    background: var(--black);
    box-shadow: 5px 5px 0 #fff, 5px 5px 0 1.5px #000;
    transition: all 0.1s linear;
    height: 62px;
    min-width: 300px;
}
button[data-tc-button="true"] svg {
    display: none;
}
button[data-tc-button="true"] div {
    font-family: "Lacquer", system-ui;
    font-size: 1.75rem;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background: var(--white);

    &__body {
        height: calc(100% - 75px);
    }

    &__list {
        display: flex;
        justify-content: space-between;
    }

    &__item {
        padding: 15px 10px;
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        transition: all .2s linear;
        color: var(--black);

        &:hover {
            transform: translate(0, 2px);
        }

        svg {
            width: 24px;
            height: 24px;
            margin-bottom: 4px;
        }
    }
}