@font-face {
    font-family: "Lacquer";
    font-display: swap;
    src: url("../fonts/Lacquer-Regular.woff2") format("woff2"), url("../fonts/Lacquer-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Kumbh";
    font-display: swap;
    src: url("../fonts/KumbhSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
