.ratings {
    height: 100%;
    height: calc(100% - 75px);
    &__container {
        height: calc(100% - 75px);
    }

    &__body {
        height: calc(100% - 75px);

    }

    &__title {
        font-size: 42px;
        margin: 12px 0 16px;
    }

    &__links {
        margin-bottom: 16px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        overflow-x: auto;
        margin-left: -15px;
		margin-right: -15px;
		padding-left: 15px;
		padding-right: 15px;

        &::-webkit-scrollbar {
            width: 100%;
            height: 0;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
          }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;
        overflow-y: auto;
        height: calc(100% - 75px);      
    }
    &__listItem {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 52px;
        min-height: 52px;
        border-radius: 16px;
        .ratings__listId {
			font-weight: 700;			
			line-height: 100%;
			letter-spacing: 0.02em;
			text-align: center;
			svg {
				display: none;
				position: absolute;
                top: 7px;
			}
		}
		.ratings__listName {
			font-weight: 400;
			font-size: 18px;
			line-height: 100%;
			letter-spacing: 0.02em;
		}
		.ratings__listBalance {
			font-weight: 700;
			font-size: 22px;
			line-height: 100%;
			letter-spacing: 0.02em;
		}
    }
    &__listItem:first-child {
		border: 1.50px solid #fff500;
        background: rgba(255, 245, 0, 0.08);
		.ratings__listId {
			svg {
				display: block;
				path {
					fill: #FFE351;
				}
			}
            span {
                color: #000000!important;
            }
		}
        .ratings__listName {
            span {
                color: #000000!important;
            }
        }
    }
    &__listItem:nth-child(2) {
        border: 1.50px solid #abb0bc;
		background: rgba(171, 176, 188, 0.08);
		.ratings__listId {
			svg {
				display: block;
				path {
					fill: #ABB0BC;
				}
			}
            span {
                color: #000000!important;
            }
		}
        .ratings__listName {
            span {
                color: #000000!important;
            }
        }
    }
    &__listItem:nth-child(3) {
        background: rgba(255, 150, 26, 0.08);
        border: 1.50px solid #ff961a;
		.ratings__listId {
			svg {
				display: block;
				path {
					fill: #FF961A;
				}
			}
            span {
                color: #000000!important;
            }
		}
        .ratings__listName {
            span {
                color: #000000!important;
            }
        }

    }
    &__itemData {
        align-items: center;
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
    }
    &__listId {
        font-weight: 400;
        font-size: 18px;
        text-transform: lowercase;
        color: #000;
        position: relative;
        width: 70px;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            height: 43px;
        }
    }
    &__listName {
        font-weight: 400;
        font-size: 22px;
        text-transform: lowercase;
        color: #000;
        margin-left: 24px;
    }
    &__listBalance {
        display: flex;
        font-weight: 400;
        font-size: 22px;
        color: #000;
        position: absolute;
        right: 11px;
        width: fit-content;
        align-items: center;
        height: 38px;
        background: #fff500;
        padding: 0 15px;
        border-radius: 12px;
        svg {
            width: 12px;
            height: 12px;
            margin-left: 2.5px;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        padding: 4px;
    }

    &__image {
        width: 45px;
        height: 45px;
        min-width: 45px;
        position: relative;
        margin-right: 12px;
        display: flex;
    align-items: center;
    justify-content: center;
        img {
            width: 80%;
            height: 80%;
            border-radius: 50%;
        }
    }

    &__name {
        flex: 1 1 auto;
        font-size: 22px;
        margin-right: 12px;
    }

    &__value {
        font-size: 22px;
        display: flex;
        align-items: center;
        background: var(--yellow);
        border-radius: 50px;
        padding: 5px 16px;
        border-radius: 12px;
        margin-right: 3px;
        svg {
            width: 12px;
            height: 12px;
            margin-left: 2px;
        }
    }
}