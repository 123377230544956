.maintenance {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    &__header {
        font-weight: 400;
        font-size: 120px;
        line-height: 43%;
        letter-spacing: -0.1em;
        text-align: center;
        color: #fff500;
    }
    &__title {
        font-weight: 400;
        font-size: 32px;
        text-align: center;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 15px;
    }
    &__descr {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #000;
        font-family: "Kumbh";
        margin-top: 15px;
    }
}