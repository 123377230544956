.qrComponent {
	z-index: 30;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  justify-content: center;
}

.qrComponentText {
  display: flex;
  align-items: center;
  padding: 20px 25px 22px 25px;

  h4 {
    font-weight: 400;
    font-size: 32px;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-align: center;
    color: var(--altColor);
    font-family: var(--font-family);
  }
}