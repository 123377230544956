$secondaryFont: "Kumbh";

.frens {
    height: calc(100% - 75px);
    display:flex;
    flex-direction: column;
    &__main {
        background: var(--yellow);
        border-radius: 0 0 36px 36px;
        padding: 52px 0 30px 0;
    }
    &__options {
        height: 100%;
    }

    &__container {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    &__title {
        font-size: 52px;
        margin-bottom: 10px;
        text-align: center;
        line-height: 1;
    }

    &__text {
        font-size: 22px;
        margin-bottom: 16px;
        text-align: center;
        font-family: $secondaryFont;
    }
}

.profile {
    height: calc(100% - 75px);
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .rive_container_profile {
        border-radius: 100%;
        background: #fff500;
        width: 249px;
        height: 249px;
        position: relative;
        img {
            left: 16px;
            width: 210px;
            height: 228px;
            position: absolute;
        }
    }
    .profile_user_name {
        font-size: 22px;
        margin-top: 6px;
    }
    .referals_level {
        height: 36px;
        background: #fff500;
        padding: 0 16px;
        border-radius: 44px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 18px;
        margin-top: 8px;
        svg {
            margin-right: 5px;
        }
    }
    .referals_count {
        display: flex;
        justify-content: space-around;
        margin-top: 17px;
        font-size: 32px;
    }
    .referals_count_item {
        font-size: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        padding: 0 10px;
        span {
            font-family: $secondaryFont;
            font-size: 14px;
        }
    }
    .referals_count_item:nth-child(2) {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
    #ton-connect-button button[data-tc-button="true"] div[data-tc-text="true"] {
        display: none;
    }
    #ton-connect-button button[data-tc-button="true"] {
        width: 100%;
        position: absolute;
        height: 100%;
        margin-top: 0;
        max-width: 100%;
        max-height: 100%;
        box-shadow: none;
        background-color: transparent;
        padding: 0;
        min-width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
    }
}

[data-tc-dropdown-container="true"] {
    top: 70px!important;
    // left: 162px!important;
}