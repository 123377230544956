.startEvent {
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	overflow-y: scroll;
	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__img {
		width: 130px;
		height: 130px;
		img {
			width: 100%;
		}
	}
	&__icon {
		margin-top: 12px;
	}
	&__title {
		font-family: 'Kumbh';
		font-weight: 400;
		font-size: 22px;
		text-align: center;
		margin-top: 4px;
		max-width: 270px;
	}
	&__descr {
		font-family: 'Kumbh';
		font-weight: 400;
		font-size: 16px;
		line-height: 125%;
		text-align: center;
		margin-top: 12px;
		max-width: 270px;
	}
	&__item {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top: 24px;
		background: #fff500;
		border-radius: 36px 36px 0 0;
		padding: 24px 0;
	}
	&__item-box {
		display: flex;
		flex-direction: column;
		gap: 4px;
		margin-top: 14px;
	}
	&__item-box:first-child {
		margin-top: 0;
	}
	&__item-title {
		span {
			font-weight: 400;
			font-size: 18px;
			line-height: 122%;
		}
	}
	&__item-descr {
		span {
			font-family: 'Kumbh';
			font-weight: 400;
			font-size: 14px;
			line-height: 143%;
		}
	}
	&__item-btn {
		display: flex;
		justify-content: center;
		margin-top: 22px;
		button {
			width: 100%;
		}
	}
}

.startEventModal {
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	border-radius: 32px 32px 0 0;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;
    padding: 31px 0 26px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
	.modal__close {
		svg {
			path {
				fill: #000000;
			}
		}
	}
    &__text {
        font-weight: 400;
        font-size: 22px;
        text-transform: lowercase;
        text-align: center;
        margin-top: 10px;
		p {
			font-family: "Kumbh";
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			text-align: center;
			color: #000;
			margin-top: 10px;
		}
    }
    &__btn-group {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 15px;
		width: 100%;
		padding: 0 16px;
		button {
			width: 100%;
		}
    }
	&__price {
		height: 60px;
		margin-top: 10px;
		display: flex;
		background: #fff500;
		padding: 0 15px;
		border-radius: 44px;
		align-items: center;
		span {
			font-size: 28px;
		}
		svg {
			width: 20px;
			height: 20px;
			margin-left: 10px;
		}
	}
	&__balance {
		margin-top: 10px;
		display: flex;
		align-items: center;
		font-size: 22px;
		color: #000;
		span {
			font-family: "Kumbh";
			font-size: 16px;
			color: #000;
		}
		svg {
			width: 12px;
			height: 12px;
		}
	}
}
