.projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 75px);

	&__slider {
			position: relative;
			&::before {
					content: "";
					position: absolute;
					top: -12px;
					left: 50%;
					transform: translate(-50%, 0);
					width: 80%;
					height: 80%;
					border-radius: 36px;
					background: var(--white);
					border: 1.5px solid #000;
			}
			&::after {
					content: "";
					position: absolute;
					top: -6px;
					left: 50%;
					transform: translate(-50%, 0);
					width: 90%;
					height: 90%;
					border-radius: 36px;
					background: var(--yellow);
					border: 1.5px solid #000;
			}
	}

	&__card {
			display: flex;
			flex-direction: column;
			align-items: center;
			border-radius: 36px;
			border: 1.5px solid #000;
			padding: 18px;
			position: relative;
			background: var(--white);
			height: 100%;
			justify-content: space-around;

			&_outline {
					border: 1.5px solid #000;
			}
	}	

	&__navigation {
			position: absolute;
			left: 10px;
			right: 10px;
			top: 185px;
	}
}
@media (max-height: 730px) {
	.projects__card {
		padding: 10px 20px 0 20px;
	}	
	.projects__navigation {
		top: 150px;
	}	
}