.navigation {
    display: flex;
    justify-content: space-between;
    z-index: 10;
    pointer-events: none;

    &__prev,
    &__next {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: all;

        &.disabled,
        &.lock {
            pointer-events: none;
            opacity: 0.5;
        }

        svg {
            width: 24px;
            height: 24px;
        }
    }
}