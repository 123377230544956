.ratings {
	height: 100%;
	width: 100%;
	height: calc(100% - 75px);

	&::-webkit-scrollbar {
		width: 100%;
		height: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: transparent;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&__container {
		height: calc(100% - 75px);
	}

	&__body {
		height: calc(100% - 75px);
	}

	&__close {
		position: absolute;
		top: 20px;
		right: 20px;

		svg {
			path {
				fill: var(--black);
			}
		}
	}

	&__listItem {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 52px;
		min-height: 52px;
		border-radius: 16px;

		.ratings__listName {
			font-weight: 400;
			font-size: 18px;
			line-height: 100%;
			letter-spacing: 0.02em;
		}
	}

	&__listItem:first-child {
		border: 1.50px solid #fff500;
		background: rgba(255, 245, 0, 0.08);

		.ratings__listId {
			svg {
				display: block;

				path {
					fill: #FFE351;
				}
			}

			span {
				color: #000000 !important;
			}
		}

		.ratings__listName {
			span {
				color: #000000 !important;
			}
		}
	}

	&__listItem:nth-child(2) {
		border: 1.50px solid #abb0bc;
		background: rgba(171, 176, 188, 0.08);

		.ratings__listId {
			svg {
				display: block;

				path {
					fill: #ABB0BC;
				}
			}

			span {
				color: #000000 !important;
			}
		}

		.ratings__listName {
			span {
				color: #000000 !important;
			}
		}
	}

	&__listItem:nth-child(3) {
		background: rgba(255, 150, 26, 0.08);
		border: 1.50px solid #ff961a;

		.ratings__listId {
			svg {
				display: block;

				path {
					fill: #FF961A;
				}
			}

			span {
				color: #000000 !important;
			}
		}

		.ratings__listName {
			span {
				color: #000000 !important;
			}
		}

	}

	&__itemData {
		align-items: center;
		display: flex;
		flex-direction: row;
		position: relative;
		width: 100%;
	}

	&__listName {
		font-weight: 400;
		font-size: 22px;
		text-transform: lowercase;
		color: #000;
		margin-left: 24px;
	}

	&__item {
		display: flex;
		align-items: center;
	}
	&__name {
		flex: 1 1 auto;
		font-size: 22px;
		margin-right: 12px;
	}
}


.highlight {
	background: #000;

	.ratings__listId {
		span {
			color: #ffffff;
		}
	}

	.ratings__listName {
		span {
			color: #ffffff;
		}
	}
}