* {
    margin: 0;
    padding: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

:focus,
:active {
    outline: none;
}

a:focus,
a:active {
    outline: none;
}

nav,
footer,
header,
aside {
    display: block;
}

input,
button,
textarea {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
    border: none;
    background: none;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a,
a:visited,
a:hover {
    text-decoration: none;
}

ul li,
ol li {
    list-style: none;
}

img {
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: 400;
}

button, 
a, 
input[type="button"], 
input[type="submit"] {
  -webkit-tap-highlight-color: transparent; 
  outline: none; 
  box-shadow: none; 
}