.notification {
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    max-width: 480px;
    opacity: 0;
    visibility: hidden;
    transition: all .8s ease;
    background: var(--white);

    &.active {
        opacity: 1;
        visibility: visible;
    }

    &_top {
        bottom: unset;
        top: 20px;

        .notification__body {
            border: 2px solid #000;
            background: var(--white);
            color: var(--black);
            border-radius: 24px;
            box-shadow: 4px 4px 0 #000;
        }
    }

    &__container {}

    &__body {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--white);
        background: var(--black);
        border-radius: 16px;
        padding: 12px 16px;

        svg {
            width: 24px;
            height: 24px;
            fill: var(--white);
        }

        p {
            max-width: 220px;
        }
    }
}


.my-notification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 20px 50px;
    border-radius: 25px;
    z-index: 1000;
    margin: 25px 10px 5px 10px;
    color: black;
    background-color: #ffffff;
    font-family: "Lacquer", system-ui;
    border: 2px solid black;
    font-weight:lighter;
    box-shadow: 5px 5px 0 0 black ;
    max-width: 100%;
    min-height: 80px;
    min-width: 280px;
    position: relative;
  }
  
  .my-notification.success {
    background-image: url('../../../../public/success.png');
    background-position: 10px center;
    background-repeat: no-repeat;
  }
  
  .my-notification.error {
    background-image: url('../../../../public/error.png');
    background-position: 10px center;
    background-repeat: no-repeat;
  }
  
  .my-notification.info {
    background-image: url('../../../../public/info.png');
    background-position: 15px center;
    background-repeat: no-repeat;
  }
  
  .tail{
    position: absolute;
    bottom: -30px;
    right: 10%;
  }
  
  .notifications-container {
    position: fixed;
    z-index: 1000; 
    display: flex;
    flex-direction: column;
    width: auto;
    justify-content: center;
    align-items: center;
    max-width:300px;
    transform: translate(-50%);
    left: 50%;
    
  }
  