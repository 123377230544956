.ratings {
	flex: 1 1 auto;

	&__links {
			margin-bottom: 20px;
			padding-bottom: 10px;
			display: flex;
			align-items: center;
			margin-left: -15px;
			margin-right: -15px;
			padding-left: 15px;
			padding-right: 15px;
	}

	&__item {
			display: flex;
			align-items: center;

	}

	&__name {
			flex: 1 1 auto;
			font-size: 22px;
			margin-right: 12px;
	}

	&__value {
			font-size: 22px;
			display: flex;
			align-items: center;
			background: var(--yellow);
			border-radius: 50px;
			padding: 5px 16px;

			svg {
					width: 12px;
					height: 12px;
					margin-left: 2px;
			}
	}
}