.splash {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: var(--app-height);

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;
    }

    &__logo {
        width: 300px;
        height: 334px;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__title {
        font-size: 54px;
    }

    &__text {
        font-size: 24px;
        text-align: center;
        padding: 20px 0;
    }
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white);
    z-index: 1000;
    transition: all .8s ease;
    opacity: 0;
    visibility: hidden;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        font-size: 32px;
        margin-bottom: 38px;
    }

    &__percent {
        font-size: 22px;
    }

    &__bar {
        width: 295px;
        height: 295px;
        position: relative;
        margin-bottom: 20px;

        svg {
            transform: rotate(-90deg);
            width: 100%;
            height: 100%;
            stroke-dasharray: 450;
            stroke-dashoffset: 450;
            stroke-width: 2px;
        }
    }

    &__image {
        border-radius: 50%;
        background: var(--yellow);
        position: absolute;
        width: 90%;
        height: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__app_title {
        font-size: 60px;
        margin-bottom: 10px;
        margin-top: 47px;
    }
    &__app_subtitle {
        font-size: 24px;
        
    }
}