.projects {
	&__slide {
		height: 655px;
	}

	&__buttons {
		width: 301px;
	}

	&__time {
		display: flex;
		flex-direction: column;
		align-items: center;
		span {
			display: flex;
			align-items: center;
			font-family: "Kumbh";
			font-weight: 400;
			font-size: 18px;
			text-align: center;
			color: #000;
			svg {
				margin-right: 8px;
			}
		}
	}

	&__progress {
		width: 100%;
		font-weight: 400;
		font-size: 22px;
		color: #000;
		text-align: center;
		display: flex;
		flex-direction: column;
		gap: 5px;
		margin-top: 10px;
		span {
			font-family: "Kumbh";
		}
	}

	&__sides {
		display: flex;
		justify-content: space-around;
		width: 100%;
	}

	&__sides-item {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__sides-img {
		 width: 130px;
		 height: 130px;
		 img {
			width: 100%;
			border-radius:50%;
		 }
	}

	&__sides-name {
		text-align: center;
		display: flex;
		align-items: center;
		span {
			font-weight: 400;
			font-size: 28px;
			text-transform: lowercase;
			color: #000;
		}
		svg {
			margin-right: 6px;
		}
	}

	&__sides-amount {
		height: 38px;
		padding: 0 20px;
		background: #fff500;
		display: flex;
		align-items: center;
		border-radius: 44px;
		margin-top: 10px;
		svg {
			margin-right: 6px;
		}
		span {
			font-weight: 400;
			font-size: 22px;
			color: #000;
		}
	}

	&__sides-btn {
		margin-top: 12px;
		button {
			width: 100%;
			font-size: 22px;
		}
	}


	&__progress-wrap {
		display: flex;
		justify-content: space-between;
	}

	&__value {
		font-size: 46px;
		display: flex;
		align-items: center;
		line-height: 1;
		margin-bottom: 5px;

		svg {
			width: 31px;
			height: 31px;
			margin-right: 10px;
			margin-left: 10px;
		}
	}

	&__rank {
		font-size: 22px;
		text-align: center;
		margin-bottom: 5px;
	}

	&__votes {
		font-size: 22px;
		margin-bottom: 10px;
		text-align: center;
	}

	&__eventVotes {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 48px;
		align-items: center;
		border: 2px solid #000;
		border-radius: 44px;
		width: auto;
	}
	&__eventVotes-left, &__eventVotes-right {
		display: flex;
		flex-direction: column;
		align-items: center;

	}
	&__eventVotes-left {
		padding: 0 12px 0 24px;
		border-right: 1px solid #abb0bc;
	}
	&__eventVotes-right {
		padding: 0 24px 0 12px;
	}
	&__eventVotes-text {
		font-family: "Kumbh";
		font-weight: 400;
		font-size: 12px;
	}
	&__eventVotes-number {
		display: flex;
		align-items: center;
		svg {
			width: 14px;
			height: 14px;
			margin-right: 4px;
			margin-left: 4px;
		}
		font-family: "Kumbh";
		font-weight: 700;
		font-size: 18px;
		color: #000;
	}


	&__image {
		width: 159px;
		height: 159px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		picture {
			width: 80%;
			height: 80%;
		}
		img {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
		svg {
			position: absolute;
			animation: pulseRotate 3s infinite ease-in-out;
		}
		@keyframes pulseRotate {
			0% {
			  transform: scale(1) rotate(0deg);
			}
			50% {
			  transform: scale(1.05) rotate(180deg);
			}
			100% {
			  transform: scale(1) rotate(360deg);
			}
		  }
	}

	&__name {
		font-size: 42px;
		margin-bottom: 14px;
		text-transform: lowercase;
	}

	&__extra {
		font-size: 24px;
		margin-bottom: 10px;
		padding: 8px 26px;
		border-radius: 50px;
		border: 1.5px solid #000;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: lowercase;
	}

	&__contract {
		font-size: 22px;
		margin-bottom: 18px;
		text-align: center;
		display: flex;
		align-items: center;
		position: relative;

		svg {
			height: 20px;
			min-width: 20px;
			width: 20px;
		}
	}

	&__contract_name {
		display: inline-block;
		width: 130px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-left: 15px;
	}

}

.contract-copied {
	position: absolute;
	backdrop-filter: blur(80px);
	background: #fff;
	width: 100%;
	color: #000;
	padding: 8px;
	border: 2px solid #000;
	border-radius: 16px;
	margin-top: 5px;
	font-size: 16px;
	align-self: center;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	pointer-events: none;
	user-select: none;
}

@media (max-height: 740px) {
	.projects__slide {
		height: 580px;
	}
}


@media (max-height: 685px) {
	.projects__slide {
		height: 500px;
	}
}
@media (max-height: 730px) {
	.projects__rank {
		font-size: 18px;
		margin-bottom: 10px;
	}
	.projects__image {
		width: 110px;
		height: 110px;
		picture {
			width: 100%;
			height: 100%;
		}
	}
	.projects__name {
		font-size: 32px;
		margin-bottom: 7px;
	}
	.projects__extra {
		height: 34px;
		font-size: 20px;
		padding: 0 22.5px;
	}
	.projects__contract {
		font-size: 18px;
	}
}

